import React, { useState } from 'react';
import styled from '../styled';
import { Button, Typography } from '@material-ui/core';
import { DemoButton } from './DemoButton';
import { EarlyAccessDialog } from './EarlyAccessDialog';
import { ResponsiveImage } from './ResponsiveImage';
import { FeatureIcon } from './FeatureGrid';
import { User, Users } from 'react-feather';
import { COLORS } from '../themes/color';

const MiniTestimonial = styled('div')`
  display: grid;
  grid-template-columns: 55px 1fr;
  grid-gap: ${(p) => p.theme.spacing(0.5)}px;
  align-items: center;
  max-width: 550px;
  padding: ${(p) => p.theme.spacing(1.5)}px;
  text-align: left;

  img {
    border-radius: 100%;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    color: #888;

    strong {
      color: #555;
    }
  }
`;

const PlanGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  left: -1%;

  @media (max-width: 1100px) {
    margin: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const PlanContainer = styled<'div', { selected: boolean }>('div')`
  padding: ${(p) => p.theme.spacing(10)}px ${(p) => p.theme.spacing(2)}px ${(
  p
) => p.theme.spacing(10)}px;
  border-radius: 16px;

  @media (min-width: 600px) {
    box-shadow: ${(p) =>
      p.selected ? '0px 2px 15px rgba(0, 0, 0, 0.1)' : 'none'};
    }
  }

  @media (max-width: 600px) {
    &:first-child {
      order: 2;
    }

    &:last-child {
      order: 1;
    }
  }
`;

export const PricingTable = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <PlanGrid>
        <PlanContainer selected={false} style={{ opacity: 0.7 }}>
          <FeatureIcon
            style={{ margin: '0 auto 12px', backgroundColor: COLORS.PAPER }}
          >
            <User size={20} />
          </FeatureIcon>
          <Typography
            variant="h5"
            paragraph
            component="h3"
            color="textSecondary"
            style={{ fontWeight: 900 }}
          >
            Creators
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Track all your affiliate revenue in one place, with features and
            tools tailored for creator-led businesses.
          </Typography>
          <br />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Request early acccess
          </Button>
          <br />
          <br />
          <br />
          <br />
          <MiniTestimonial>
            <ResponsiveImage
              src="/images/testimonials/shelley-marmor.png"
              alt="Shelley Marmor"
              width="45"
              height="45"
              style={{ filter: 'grayscale(1) brightness(1)' }}
            />
            <p>
              “If you're serious about affiliate marketing, you need Affilimate.
              It's become a <strong>game-changer for</strong> our business.”
              &mdash; Shelley Marmor, <em>Travel Mexico Solo</em>
            </p>
          </MiniTestimonial>
          <MiniTestimonial style={{ opacity: 0.7 }}>
            <ResponsiveImage
              src="/images/testimonials/Claire.jpeg"
              alt="Claire Robinson"
              width="45"
              height="45"
              style={{ filter: 'grayscale(1) contrast(1.2)' }}
            />
            <p>
              “Affilimate helped me take a single offer from $500 per year to{' '}
              <strong>almost $7,000</strong> in the last four months.” &mdash;
              Claire Robinson, <em>ZigZag Roadtrips</em>
            </p>
          </MiniTestimonial>
        </PlanContainer>
        <PlanContainer selected={true}>
          <FeatureIcon
            style={{
              margin: '0 auto 12px',
              backgroundColor: COLORS.PAPER_DARK
            }}
          >
            <Users size={20} />
          </FeatureIcon>
          <Typography
            variant="h5"
            paragraph
            component="h3"
            style={{ fontWeight: 900 }}
          >
            Media companies
          </Typography>
          <Typography variant="body2" paragraph>
            Unparalleled commerce content insights, affiliate reporting, and
            advanced tools for media companies &amp; enterprise publishers.
          </Typography>
          <br />
          <DemoButton text="Schedule a demo" variant="contained" />
          <br />
          <br />
          <br />
          <MiniTestimonial>
            <ResponsiveImage
              src="/images/testimonials/alex-elkoury.jpeg"
              alt="Alex Elkoury"
              width="45"
              height="45"
              style={{ filter: 'grayscale(1) contrast(1.2) brightness(1.05)' }}
            />
            <p>
              “In just one month of integration, we were able to discover simple
              opportunities that <strong>doubled revenue</strong> for specific
              pages.” &mdash; <em>VentureKite</em>
            </p>
          </MiniTestimonial>
          <MiniTestimonial>
            <ResponsiveImage
              src="/images/testimonials/simon-beauloye.png"
              alt="Simon Beauloye"
              width="45"
              height="45"
              style={{ filter: 'grayscale(1) brightness(0.9)' }}
            />
            <p>
              “Smartly designed, packed with thoughtful features, Affilimate
              helps us save time and increase revenue by{' '}
              <strong>optimizing our affiliate performance</strong>.” &mdash;{' '}
              <em>Luxe Digital</em>
            </p>
          </MiniTestimonial>
        </PlanContainer>
      </PlanGrid>
      <EarlyAccessDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
};
