import React from 'react';
import { Typography } from '@material-ui/core';
import PageHead from '../components/PageHead';
import { SEO } from '../components/SEO';
import ContentLayout from '../layouts/main';
import { PricingTable } from '../components/PricingTable';
import { Faq } from '../components/Faq';

export default () => {
  return (
    <ContentLayout>
      <>
        <SEO
          title="Affilimate Pricing"
          description="Learn about Affilimate's different pricing plans, and find one that suits your business no matter what stage you're at."
          pathname="/pricing/"
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '700px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Plans tailored to your business
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            component="p"
            color="textSecondary"
            style={{
              maxWidth: '700px',
              margin: '0 auto 36px'
            }}
          >
            What category does your business fall into?
          </Typography>
          <PricingTable />
        </PageHead>
      </>
      <Faq />
      <br />
      <br />
      <br />
      <br />
    </ContentLayout>
  );
};
