import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import styled from '../../styled';
import { ChevronDown } from 'react-feather';

const Question = styled('h3')`
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};
  margin: ${(p) => p.theme.spacing(1)}px 0;
`;

const Link = styled(GatsbyLink)`
  color: ${(p) => p.theme.palette.primary.main};
  transition: 0.1s border-bottom linear;
  border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};

  &:hover {
    border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
  }
`;

const PlainLink = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
  transition: 0.1s border-bottom linear;
  border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};

  &:hover {
    border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
  }
`;

export const Faq = () => (
  <div style={{ maxWidth: '800px', margin: '0 auto' }}>
    <Typography
      variant="h5"
      component="h2"
      paragraph
      style={{ fontWeight: 700, textAlign: 'center', marginBottom: '2rem' }}
    >
      Frequently Asked Questions
    </Typography>

    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={20} />}
        aria-controls="q1"
        id="q1"
      >
        <Question>Will Affilimate work for our website or CMS?</Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          Most likely, yes! Affilimate works with all major website builders and
          CMS solutions, and can be integrated with custom sites as well.
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={20} />}
        aria-controls="q1"
        id="q1"
      >
        <Question>How long does it take to set up Affilimate?</Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          Even publishers with custom tech stacks are typically able to go from
          kickoff to live with a trial in under 2 weeks. For publishers with a
          standard setup, account configuration and your first insights takes
          less than 24 hours and often doesn't require developer resources.
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={20} />}
        aria-controls="q1"
        id="q1"
      >
        <Question>Does Affilimate have an API?</Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          Yes, Affilimate has a variety of publisher API endpoints for tasks
          like pulling commission data, sending custom events, searching your
          advertisers, and generating links. Reach out to us with your use case
          and we'll be able to share more about our API's capabilities.
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={20} />}
        aria-controls="q1"
        id="q1"
      >
        <Question>
          Does Affilimate integrate with Google Analytics or AEM?
        </Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          Affilimate tracks all its own event data, including pageviews, clicks,
          and impressions. For this reason, it's not necessary to rely on this
          data from Google Analytics, Adobe Experience Manager, or other
          third-party web analytics tools. This out-of-the-box approach saves
          you engineering time and resources, allows Affilimate to offer more
          advanced and custom features, and often results in more complete data.
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={18} />}
        aria-controls="q2"
        id="q2"
      >
        <Question>
          Which networks and platforms does Affilimate integrate with?
        </Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          Affilimate integrates with 100+ affiliate platforms, and thousands of
          programs through our integrations.
          <br />
          <br />
          You can search a list of the affiliate platforms we support on our{' '}
          <Link to="/integrations/">Integrations page</Link>.
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={18} />}
        aria-controls="q2"
        id="q2"
      >
        <Question>How can I speak with someone from your team?</Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          Get in touch via <Link to="/contact/">our contact form</Link>! We're
          excited to talk about affiliate marketing and analytics with you, and
          see if our platform is a fit.
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ChevronDown size={20} />}
        aria-controls="q1"
        id="q1"
      >
        <Question>
          Where can we learn more about Affilimate's features?
        </Question>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="p"
          style={{ color: '#444' }}
          paragraph
        >
          You can learn more about Affilimate's core features on our{' '}
          <Link to="/product/">features hub</Link>, or{' '}
          <Link to="/demo/">book a demo</Link> with our team to see how
          Affilimate can help your business.
        </Typography>
      </AccordionDetails>
    </Accordion>
  </div>
);
